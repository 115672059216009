<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="lectura">
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CALIDAD</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <template>
      <v-row v-for="(data) in this.gestion[0].json_respu" :key="data.id">
       <v-col v-if="data.pregunta" md="12">
        <v-text-field
        disabled
        :label="data.pregunta + ': ' + data.respuesta "
        >
        </v-text-field>
       </v-col>
       <v-col v-if="data.nota">
        <v-text-field
        disabled
        :label="'Nota: ' + data.nota "
        >
        </v-text-field>
       </v-col>
       <v-col v-if="data.auditor">
        <v-text-field
        disabled
        :label="'Auditor ' + data.auditor "
        >
        </v-text-field>
       </v-col>
        </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
import recCalidad from '@/js/rec_calidad.js'
export default {
  name: 'lecturaCalidadComponent',
  components: {
  },
  mixins: [recCalidad],
  created () {
    this.rec_consultar_gestion_calidad(this.gestion_id)
  },
  watch: {
    estadoLectura: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_consultar_gestion_calidad(this.gestion_id)
      }
    }
  },
  props: ['colores', 'rules', 'items', 'gestion_id', 'session', 'estadoLectura'],
  data: () => ({
    lectura: false,
    gestion: []
  }),
  methods: {
  }
}
</script>
